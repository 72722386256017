import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeader from './PageHeader';
import check from '../img/check.svg';
import shinerImage from '../img/shiner.jpg';

const classNames = require('classnames');

function Radio({
    stateKey,
    value,
    label,
    data,
    updateData,
}) {
    const on = data[stateKey] === value;
    const className = classNames('button', { '-on': on });

    return (
        <button
            type="button"
            className={className}
            onClick={() => updateData(stateKey, value)}
        >
            <img className="icon" alt="" width="16" height="16" src={check} />
            {label}
        </button>
    );
}

Radio.propTypes = {
    stateKey: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    updateData: PropTypes.func.isRequired,
    data: PropTypes.shape({
        sleepless: PropTypes.bool,
        shiner: PropTypes.bool,
    }).isRequired,
};

function YesNo({
    stateKey,
    question,
    children,
    data,
    updateData,
}) {
    return (
        <div className="symptoms-question">
            <div className="question">{question}</div>
            <div className="answers">
                <Radio
                    stateKey={stateKey}
                    value
                    label="Yes"
                    data={data}
                    updateData={updateData}
                />
                <Radio
                    stateKey={stateKey}
                    value={false}
                    label="No"
                    data={data}
                    updateData={updateData}
                />
                {children}
            </div>
        </div>
    );
}

YesNo.defaultProps = {
    children: null,
};

YesNo.propTypes = {
    stateKey: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    children: PropTypes.node,
    updateData: PropTypes.func.isRequired,
    data: PropTypes.shape({
        sleepless: PropTypes.bool,
        shiner: PropTypes.bool,
    }).isRequired,
};


export default function SymptomsScreen({ setScreen, updateData, data }) {
    useEffect(() => {
        if (data.sleepless !== null && data.shiner !== null) {
            setScreen('report');
        }
    });

    return (
        <div className="symptoms-screen">
            <PageHeader page={3}>Other symptoms</PageHeader>
            <YesNo
                stateKey="sleepless"
                question="Is baby scratching at night?"
                data={data}
                updateData={updateData}
            />
            <YesNo
                stateKey="shiner"
                question="Do baby's eyes ever look like this?"
                data={data}
                updateData={updateData}
            >
                <figure className="figure">
                    <img
                        className="image"
                        // width="120"
                        height="120"
                        src={shinerImage}
                        alt=""
                    />
                    <figcaption className="caption">Allergic shiners</figcaption>
                </figure>
            </YesNo>
        </div>
    );
}

SymptomsScreen.propTypes = {
    setScreen: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    data: PropTypes.shape({
        sleepless: PropTypes.bool,
        shiner: PropTypes.bool,
    }).isRequired,
};
