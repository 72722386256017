import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from './PageHeader';
import check from '../img/check.svg';

const classNames = require('classnames');

function ExtentArea({
    name,
    stateKey,
    updateData,
    data,
}) {
    const on = data[stateKey];
    const className = classNames('button', { '-on': on });

    return (
        <button
            type="button"
            className={className}
            onClick={() => updateData(stateKey, !data[stateKey])}
        >
            <img className="icon" alt="" width="16" height="16" src={check} />
            {name}
        </button>
    );
}

ExtentArea.propTypes = {
    name: PropTypes.string.isRequired,
    stateKey: PropTypes.string.isRequired,
    updateData: PropTypes.func.isRequired,
    data: PropTypes.shape({
        face: PropTypes.bool,
        diaper: PropTypes.bool,
        elbows: PropTypes.bool,
        knees: PropTypes.bool,
        more: PropTypes.bool,
    }).isRequired,
};

export default function ExtentScreen({ setScreen, updateData, data }) {
    return (
        <div className="extent-screen">
            <PageHeader page={1}>Where are you noticing problems?</PageHeader>
            <div className="extent-form">
                <ExtentArea name="Face" stateKey="face" data={data} updateData={updateData} />
                <ExtentArea name="Diaper area" stateKey="diaper" data={data} updateData={updateData} />
                <ExtentArea name="Inside the elbows" stateKey="elbows" data={data} updateData={updateData} />
                <ExtentArea name="Behind the knees" stateKey="knees" data={data} updateData={updateData} />
                <ExtentArea name="Other places" stateKey="more" data={data} updateData={updateData} />
            </div>
            <button
                className="button -primary"
                type="button"
                onClick={() => setScreen('intensity')}
                disabled={Object.keys(data).every(key => !data[key])}
            >
                Next
            </button>
        </div>
    );
}

ExtentScreen.propTypes = {
    setScreen: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    data: PropTypes.shape({
        face: PropTypes.bool,
        diaper: PropTypes.bool,
        elbows: PropTypes.bool,
        knees: PropTypes.bool,
        more: PropTypes.bool,
    }).isRequired,
};
