import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

export default function Progress({ page }) {
    return (
        <div className="progress">
            <span className={classNames('page', { '-on': page === 1 })}>•</span>
            <span className={classNames('page', { '-on': page === 2 })}>•</span>
            <span className={classNames('page', { '-on': page === 3 })}>•</span>
        </div>
    );
}

Progress.propTypes = {
    page: PropTypes.number.isRequired,
};
