import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PageHeader from './PageHeader';
import mildImage from '../img/mild.jpg';
import moderateImage from '../img/moderate.jpg';
import severeImage from '../img/severe.jpg';

const classNames = require('classnames');

const images = {
    1: mildImage,
    2: moderateImage,
    3: severeImage,
};

function IntensityInput({
    value,
    label,
    updateData,
}) {
    const className = classNames('button', { '-noimage': value === 0 });

    return (
        <button
            type="button"
            className={className}
            onClick={() => updateData('intensity', value)}
        >
            {!!value && (
                <img
                    className="image"
                    width="160"
                    src={images[value]}
                    alt={`Example of ${label} eczema`}
                />
            )}
            {label}
        </button>
    );
}

IntensityInput.propTypes = {
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    updateData: PropTypes.func.isRequired,
};

export default function IntensityScreen({ setScreen, updateData, data }) {
    useEffect(() => {
        if (data.intensity !== null) {
            setScreen('symptoms');
        }
    });

    return (
        <div className="intensity-screen">
            <PageHeader page={2}>The problem areas skin looks most like…</PageHeader>
            <div className="intensity-form">
                <IntensityInput value={1} label="Mild" updateData={updateData} />
                <IntensityInput value={2} label="Moderate" updateData={updateData} />
                <IntensityInput value={3} label="Severe" updateData={updateData} />
                <IntensityInput value={0} label="It doesn't look like these" updateData={updateData} />
            </div>
        </div>
    );
}

IntensityScreen.propTypes = {
    setScreen: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    data: PropTypes.shape({
        intensity: PropTypes.number,
    }).isRequired,
};
