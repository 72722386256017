import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import PageHeader from './PageHeader';
import startOver from '../img/startover.svg';

export default function ReportScreen({
    setScreen,
    data: {
        face,
        diaper,
        elbows,
        knees,
        more,
        intensity,
        sleepless,
        shiner,
    },
}) {
    const intensityDesc = ['Uncertain', 'Mild', 'Moderate', 'Severe'][intensity];

    return (
        <div className="report-screen">
            <PageHeader donezo>Your baby may have eczema</PageHeader>
            <h1 className="print-heading">Infant Eczema Assessment</h1>
            <p className="overview">
                Babies with eczema are at increased risk of developing food allergies
                and asthma. Talk to your pediatrician about protecting your
                baby&apos;s skin and introducing common allergens early.
                {' '}
                <a className="link" href="https://www.lilmixins.com">
                    See how Lil&nbsp;Mixins can help
                </a>
                .
            </p>
            <p className="instructions">
                Print this page and bring it to your next pediatrician appointment.
            </p>
            <div className="results-section">
                <div className="results-item">
                    <h2 className="heading">Infant may have eczema:</h2>
                    <ul className="list">
                        {face && <li className="listitem">On the face</li>}
                        {diaper && <li className="listitem">In the diaper area</li>}
                        {elbows && <li className="listitem">Inside the elbows</li>}
                        {knees && <li className="listitem">Behind the knees</li>}
                        {more && <li className="listitem">In uncommon areas</li>}
                    </ul>
                </div>
                <div className="results-item">
                    <h2 className="heading">
                        Intensity:&emsp;
                        <strong>{intensityDesc}</strong>
                    </h2>
                </div>
                <div className="results-item">
                    <h2 className="heading">
                        Sleeplessness?&emsp;
                        <strong>
                            {sleepless ? 'Yes' : 'No'}
                        </strong>
                    </h2>
                </div>
                <div className="results-item">
                    <h2 className="heading">
                        Allergic shiners?&emsp;
                        <strong>
                            {shiner ? 'Yes' : 'No'}
                        </strong>
                    </h2>
                </div>
            </div>
            <button
                className="button -primary"
                type="button"
                onClick={() => {
                    ReactGA.event({
                        category: 'General',
                        action: 'Print',
                    });
                    window.print();
                }}
            >
                Print
            </button>
            <button
                className="button -text"
                type="button"
                onClick={() => setScreen('welcome')}
            >
                <img className="icon" alt="" width="12" height="12" src={startOver} />
                Start over
            </button>
            <div className="resources">
                <h2 className="heading">Additional resources from Lil Mixins</h2>
                <ul className="list">
                    <li className="listitem">
                        <a
                            href="https://cdn.shopify.com/s/files/1/2531/6758/files/Lil_Mixins_Guide_To_Early_Intro_Print.pdf?257"
                            className="link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Guide to early introduction
                        </a>
                    </li>
                    <li className="listitem">
                        <a
                            href="https://www.lilmixins.com/pages/parent-resource-center"
                            className="link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Parent resource center
                        </a>
                    </li>
                    <li className="listitem">
                        <a
                            href="https://www.lilmixins.com/pages/faq-frequently-asked-questions"
                            className="link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            FAQ
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

ReportScreen.propTypes = {
    setScreen: PropTypes.func.isRequired,
    data: PropTypes.shape({
        face: PropTypes.bool,
        diaper: PropTypes.bool,
        elbows: PropTypes.bool,
        knees: PropTypes.bool,
        more: PropTypes.bool,
        intensity: PropTypes.number,
        sleepless: PropTypes.bool,
        shiner: PropTypes.bool,
    }).isRequired,
};
