import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import PageHeader from './PageHeader';
import welcomeImage from '../img/welcome.jpg';

export default function WelcomeScreen({ setScreen }) {
    return (
        <div className="welcome-screen">
            <PageHeader>Does My Baby Have Eczema?</PageHeader>
            <p className="desc">
                You&apos;ve noticed your baby&apos;s skin isn&apos;t &ldquo;baby perfect&rdquo;.
                But is it eczema? Use this tool to help tell.
            </p>
            <img
                className="image"
                // width="200"
                height="240"
                src={welcomeImage}
                alt=""
            />
            <button
                className="button -primary"
                type="button"
                onClick={() => {
                    ReactGA.event({
                        category: 'General',
                        action: 'Start',
                    });
                    setScreen('extent');
                }}
            >
                Get started
            </button>
        </div>
    );
}

WelcomeScreen.propTypes = {
    setScreen: PropTypes.func.isRequired,
};
