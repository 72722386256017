import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'focus-visible';
import 'inert-polyfill';

import '../sass/main.scss';

const root = document.querySelector('#root');
ReactDOM.render(React.createElement(App), root);
