import React from 'react';
import PropTypes from 'prop-types';
import Progress from './Progress';

const classNames = require('classnames');

export default function PageHeader({ children, donezo, page }) {
    const className = classNames('page-header', { '-donezo': donezo });

    return (
        <header className={className}>
            <h1 className="heading">{children}</h1>
            {page && <Progress page={page} />}
        </header>
    );
}

PageHeader.defaultProps = {
    donezo: false,
    page: null,
};

PageHeader.propTypes = {
    children: PropTypes.node.isRequired,
    donezo: PropTypes.bool,
    page: PropTypes.number,
};
