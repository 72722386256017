import React from 'react';
import Logo from '../img/LilMixins_Logo_RGB.png';

export default function Footer() {
    return (
        <footer className="footer">
            Brought to you by
            <a
                className="link"
                href="https://www.lilmixins.com"
                title="Visit Lil Mixins"
            >
                <img src={Logo} height="48" alt="Lil Mixins" />
            </a>
        </footer>
    );
}
