import React from 'react';
import ReactGA from 'react-ga';
import WelcomeScreen from './WelcomeScreen';
import ExtentScreen from './ExtentScreen';
import IntensityScreen from './IntensityScreen';
import SymptomsScreen from './SymptomsScreen';
import ReportScreen from './ReportScreen';
import Footer from './Footer';

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            screenName: 'welcome',
            data: {
                face: null,
                diaper: null,
                elbows: null,
                knees: null,
                more: null,
                intensity: null,
                sleepless: null,
                shiner: null,
            },
        };
        ReactGA.initialize('UA-116598164-2');
    }

    setScreen = (screenName) => {
        // When (re)mounting a screen, clear its associated state.
        // Currently only relevant when restarting back to welcome screen
        // but useful if we allow navigating Back from any screen.
        let stateToClear;
        switch (screenName) {
            case 'welcome':
                stateToClear = {
                    face: null,
                    diaper: null,
                    elbows: null,
                    knees: null,
                    more: null,
                    intensity: null,
                    sleepless: null,
                    shiner: null,
                };
                break;
            case 'extent':
                stateToClear = {
                    face: null,
                    diaper: null,
                    elbows: null,
                    knees: null,
                    more: null,
                };
                break;
            case 'intensity':
                stateToClear = {
                    intensity: null,
                };
                break;
            case 'symptoms':
                stateToClear = {
                    sleepless: null,
                    shiner: null,
                };
                break;
            default:
                stateToClear = {};
                break;
        }

        const { data } = this.state;

        window.setTimeout(() => this.setState({
            screenName,
            data: {
                ...data,
                ...stateToClear,
            },
        }), 150);
    }

    updateData = (key, value) => this.setState(state => ({
        data: {
            ...state.data,
            [key]: value,
        },
    }));

    render() {
        const { screenName, data } = this.state;

        const Screen = {
            welcome: WelcomeScreen,
            extent: ExtentScreen,
            intensity: IntensityScreen,
            symptoms: SymptomsScreen,
            report: ReportScreen,
        }[screenName];

        return (
            <>
                <Screen
                    setScreen={this.setScreen}
                    updateData={this.updateData}
                    data={data}
                />
                <Footer />
            </>
        );
    }
}
